<template>
  <div class="layout-config h-100 p-20">
    <h3 class="b-b-1 m-b-10">{{ $route.query.id ? '编辑' : '新增' }}研学资讯</h3>
    <Form ref="form" :model="formItem" :rules="rules" :label-width="100" label-colon>
        <FormItem label="资讯标题" prop="newTitle" class="w-40">
            <Input v-model="formItem.newTitle" placeholder="请输入资讯标题" clearable />
        </FormItem>
        <FormItem label="标签" class="w-40">
            <Input v-model="formItem.tag" placeholder="请输入标签" clearable />
        </FormItem>
        <!-- <FormItem label="所属机构" class="w-40">
            <Input v-model="formItem.newOrg" placeholder="所属机构" readonly />
        </FormItem> -->
        <!-- <FormItem label="创建人" prop="newEdit" class="w-40">
            <Input v-model="formItem.newEdit" placeholder="请输入创建人名称" clearable />
        </FormItem> -->
        <!-- <FormItem label="创建时间" class="w-40">
            <MyDataPicker v-model="formItem.newDate" format="yyyy-MM-dd" clearable />
        </FormItem> -->
        <FormItem label="正文内容" prop="newContent" class="w-70">
            <WangEditor ref="editor" />
        </FormItem>
        <FormItem class="w-50">
            <Button type="success" @click="saveData">提交</Button>
            <Button type="default" class="m-l-5" @click="goBack">返回</Button>
        </FormItem>
    </Form>
  </div>
</template>

<script>
import { addNews, updateNews, getNewsById } from '@/libs/api/researchNews'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            formItem: {},
            rules: {}
        }
    },
    computed: {
        ...mapState({
            organization: state => state.user.organization,
            userId: state => state.user.userId
        })
    },
    methods: {
        // 返回列表
        goBack() {
            this.$router.push('/config/dataManage/researchNews')
        },

        // 保存数据
        saveData() {
            this.$refs.form.validate(valid => {
                if(valid) {
                    let content = this.$refs.editor.getHtml()
                    content == '<p><br></p>' ? this.formItem.newContent = null : this.formItem.newContent = content
                    // this.formItem.newOrgId = this.organization.organizationId
                    // this.formItem.newEditorId = this.userId
                    if(this.formItem.researchNewId) {
                        // 修改API
                        updateNews(this.formItem).then(res => {
                            this.successResult(res, '编辑成功!')
                        });
                    } else {
                        // 新增API
                        addNews(this.formItem).then(res => {
                            this.successResult(res, '添加成功!')
                        });
                    }
                }
            })
        },
        // 新增修改成功回调
        successResult(res, descMsg) {
            if (res.code == "HA0200") {
                this.$Message.success(descMsg || res.msg);
                this.goBack();
            }
            else {
                this.$Message.error(res.msg);
            }
        },
    },
    async created() {
        const { id } = this.$route.query
        if(id) {
            const { code, data } = await getNewsById({ new_id: id})
            if(code == "HA0200") {
                this.$refs.editor.setHtml(data.newContent)
                this.formItem = data
            }
        } else {
            this.formItem = {
                // newOrg: this.$store.state.user.organization.organizationName
            }
        }
    }
}
</script>

<style>

</style>