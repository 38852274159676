import { request } from '@/libs'

/** 
 * 研学资讯API
 */

// 根据用户访问权限查询已有研学点
export function getNewsAll() {
    return request.get('/yanxue-app/research/new/all')
}

// 分页查询
export function getNews(params) {
    return request.post(`/yanxue-app/research/new/findbypage`, params)
}

// 根据研学点id获取详情
export function getNewsById(params) {
    return request.get('/yanxue-app/research/new/info', { params })
}

// 添加
export function addNews(params) {
    return request.post('/yanxue-app/research/new/add', params)
}

// 编辑
export function updateNews(params) {
    return request.put(`/yanxue-app/research/new/update?new_id=${params.researchNewId}`, params)
}

// 删除
export function deleteNews(params) {
    return request.delete('/yanxue-app/research/new/delete', { params })
}
